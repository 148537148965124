import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import ProjectsView from "../views/ProjectsView.vue";
import ThePolicy from "../views/ThePolicy.vue";
import TheHistory from "../views/TheHistory.vue";
import TheMission from "../views/TheMission.vue";
import TheCertificates from "../views/TheCertificates.vue";
// import TheNews from "../views/TheNews.vue";
// import TheSelectedNews from "../views/TheSelectedNews.vue";
import TheSelectedProject from "../views/TheSelectedProject.vue";
import TheSelectedService from "../views/TheSelectedService.vue";
import TheManagement from "../views/TheManagement.vue";
import UnderConstruction from "../views/UnderConstruction.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "HomeView",
    component: HomeView,
  },
  {
    path: "/projects",
    name: "ProjectsView",
    component: ProjectsView,
  },
  {
    path: "/projects/:project",
    name: "TheSelectedProject",
    component: TheSelectedProject,
  },
  {
    path: "/policy",
    name: "ThePolicy",
    component: ThePolicy,
  },
  {
    path: "/history",
    name: "TheHistory",
    component: TheHistory,
  },
  {
    path: "/mission",
    name: "TheMission",
    component: TheMission,
  },
  {
    path: "/certificates",
    name: "TheCertificates",
    component: TheCertificates,
  },
  // {
  //   path: "/news",
  //   name: "TheNews",
  //   component: TheNews,
  // },
  // {
  //   path: "/news/:news",
  //   name: "TheSelectedNews",
  //   component: TheSelectedNews,
  // },
  {
    path: "/services/:service",
    name: "TheSelectedService",
    component: TheSelectedService,
  },
  {
    path: "/management",
    name: "TheManagement",
    component: TheManagement,
  },
  {
    path: "/uc",
    name: "UnderConstruction",
    component: UnderConstruction,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
